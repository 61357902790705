<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes" />Dados do projeto
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <SBFormularioDados
          :enviar="enviarFormulario"
          :projeto="projeto"
          @projetoErro="projetoSalvoErro"
          @projetoSalvo="projetoSalvoSucesso"
        ></SBFormularioDados>
      </CCardBody>
      <CCardFooter>
        <CButton color="success" size="sm" class="float-right" @click="salvarEdicaoProjeto">
          <CIcon name="cil-save"></CIcon>&nbsp;Salvar
        </CButton>
      </CCardFooter>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="(mensagem,indexMensagem) in mensagens">
        <CToast
          :key="indexMensagem"
          :show="true"
          header="Aviso"
          :class="mensagem.classe"
        >{{mensagem.mensagem}}</CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import Axios from "axios";
import SBFormularioDados from "./Formulario";
export default {
  name: "Dados",
  components: { SBFormularioDados },
  data() {
    return {
      projeto: {},
      enviarFormulario: false,
      mensagens: [],
      requisicaoAberta: false
    };
  },
  methods: {
    salvarEdicaoProjeto() {
      this.$eventoHub.$emit("salvarProjeto", true);
    },
    obtenhaProjeto() {
      this.requisicaoAberta = true;
      let idProjeto = this.$route.params.projetoID;
      Axios.get("/projeto/" + idProjeto)
        .then(response => {
          this.projeto = response.data.retorno;
          this.loading = false;
        })
        .catch(() => {
          this.$router.push({
            name: "Home"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    projetoSalvoErro() {
      this.$eventoHub.$emit("adicionarMensagemAviso", {
        mensagem: "Não foi possível salvar as alteração!",
        classe: "bg-error"
      });
    },
    projetoSalvoSucesso() {
      this.$eventoHub.$emit("adicionarMensagemAviso", {
        mensagem: "Alterações salva!",
        classe: "bg-success"
      });
    }
  },
  mounted: function() {
    this.obtenhaProjeto();
  }
};
</script>
